import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const WhatIsShamisenPage = () => {
    return (
        <Layout pageTitle="What is shamisen?" subTitle="What is shamisen?">
            <Link to="/whatIsShamisen/shamisenIntroduction/">Introduction</Link>
            <Link to="/whatIsShamisen/shamisenTypesAndStyles/">Types and Styles</Link>
            <Link to="/whatIsShamisen/shamisenNaguata/">Naguata</Link>
        </Layout>
    )
}

export default WhatIsShamisenPage

